import React, {useEffect, useRef, useState} from "react";
import {
    AppBar,
    Button,
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Slider,
    Switch,
    Toolbar,
    Typography,
    Modal, Checkbox
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import "./BgColor.css"
import CloseIcon from '@material-ui/icons/Close';
import Camera from "./Camera";
import CameraPhoneLandScape from "./Camera-phoneLandScape";

function BgColor({that}) {
    //---------------------------------------------------------------------------//
    //Initial Loading items
    if ((that.state.MobileCheck === true) && (that.state.initialLoad === true)) {
        that.state.initialHeight = that.state.ScreenHeight;
        that.state.initialWidth = that.state.ScreenWidth;
        if (that.state.ScreenHeight > that.state.ScreenWidth) {
            that.state.initialLoadPortrait = true;
        }

        if (that.state.ScreenHeight < that.state.ScreenWidth) {
            that.state.initialLoadLandscape = true;
        }

        that.state.initialLoad = false;
    }

    if ((that.state.MobileCheck === true) && (that.state.initialLoad === false)) {
        if ((that.state.ScreenHeight > that.state.ScreenWidth) && (that.state.initialLoadPortrait === true)) {
            that.state.ScreenHeight = that.state.initialHeight;
            that.state.ScreenWidth = that.state.initialWidth;
        }

        if ((that.state.ScreenHeight > that.state.ScreenWidth) && (that.state.initialLoadLandscape === true)) {
            that.state.ScreenHeight = that.state.initialWidth *.90;
            that.state.ScreenWidth = that.state.initialHeight * 1.27;
        }
    }

    //---------------------------------------------------------------------------//
    //Checked box - See RGB Values
    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        that.state.SeeRGBValues = event.target.checked;
        console.log("RGBCheckBox: " + that.state.SeeRGBValues);
    };

    const renderRGBSliders = () => {
        return (
            <div>
                <div>
                    <label>R: </label>
                    <Slider max={255} value={that.state.Red} onChange={handleChangeRed} aria-labelledby="continuous-slider" valueLabelDisplay="auto" style={{width:200}}/><br/>

                    <label>G: </label>
                    <Slider max={255} value={that.state.Green} onChange={handleChangeGreen} aria-labelledby="continuous-slider" valueLabelDisplay="auto" style={{width:200}}/><br/>

                    <label>B: </label>
                    <Slider max={255} value={that.state.Blue} onChange={handleChangeBlue} aria-labelledby="continuous-slider" valueLabelDisplay="auto" style={{width:200}}/><br/>

                    <p>R: {that.state.Red}</p>
                    <p>G: {that.state.Green}</p>
                    <p>B: {that.state.Blue}</p>
                </div>
                <div>
                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].id}</p>
                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].firstParam}</p>
                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].secondParam}</p>
                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].thirdParam}</p>
                </div>
            </div>
        )
    }

    const renderDataValues = () => {
        return (
            <div>
                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].id}</p>
                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].firstParam}</p>
                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].secondParam}</p>
                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].thirdParam}</p>
            </div>
        )
    }

    const renderData = () => {
        if (that.state.enableCamera === true) {
            if ((that.state.enableCamera === true) && ((that.state.MobileCheck === true)) && (that.state.ScreenHeight < that.state.ScreenWidth)) {
                return (
                    <div style={{height: that.state.ScreenHeight * .80, width: that.state.ScreenWidth}}>
                        <div style={{ height: "100%", width: "50%", float: "left", backgroundColor: color}}>
                            <CameraPhoneLandScape that1={that}></CameraPhoneLandScape>
                        </div>
                        <div style={{ height: "100%", width: "50%", float: "left", backgroundColor: color}}>
                            <div style={{paddingTop: (that.state.ScreenHeight * .11)}}>
                                <h4>View the camera through your devices screen and match the color as closely as
                                    possible</h4>
                                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].id}</p>
                                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].firstParam}</p>
                                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].secondParam}</p>
                                <p>{CalculatedVariables[that.state.CalculatedVariableIndex].thirdParam}</p>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div>
                        <div style={{height: that.state.ScreenHeight * .90, width: that.state.ScreenWidth}}>
                            {/*<Camera that1={that}></Camera>*/}
                            <div style={{height: "50%", width: that.state.ScreenWidth}}>
                                <Camera that1={that}></Camera>
                            </div>
                            <div style={{height: "50%", width: that.state.ScreenWidth, backgroundColor: color}}>
                                <div style={{paddingTop: (that.state.ScreenHeight * .11)}}>
                                    <h4>View the camera through your devices screen and match the color as closely as
                                        possible</h4>
                                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].id}</p>
                                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].firstParam}</p>
                                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].secondParam}</p>
                                    <p>{CalculatedVariables[that.state.CalculatedVariableIndex].thirdParam}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else if (that.state.enableCamera === false) {
            if ((that.state.SeeRGBValues === true) && ((that.state.MobileCheck === true)) && (that.state.ScreenHeight < that.state.ScreenWidth)) {
                return (
                    <div style={{paddingTop: (that.state.ScreenHeight * .20)}}>
                        {renderDataValues()}
                    </div>
                )
            } else if ((that.state.SeeRGBValues === false) && ((that.state.MobileCheck === true)) && (that.state.ScreenHeight < that.state.ScreenWidth)) {
                return (
                    <div style={{paddingTop: (that.state.ScreenHeight * .20)}}>
                        {renderDataValues()}
                    </div>
                )
            } else if ((that.state.SeeRGBValues === false) && ((that.state.MobileCheck === true)) && (that.state.ScreenHeight > that.state.ScreenWidth)) {
                return (
                    <div style={{paddingTop: (that.state.ScreenHeight * .35)}}>
                        {renderDataValues()}
                    </div>
                )
            } else if ((that.state.SeeRGBValues === true) && ((that.state.MobileCheck === true)) && (that.state.ScreenHeight > that.state.ScreenWidth)) {
                return (
                    <div style={{paddingTop: (that.state.ScreenHeight * .19)}}>
                        {renderRGBSliders()}
                    </div>
                )
            } else if ((that.state.SeeRGBValues === false) && ((that.state.MobileCheck === false))) {
                return (
                    <div style={{paddingTop: (that.state.ScreenHeight * .35)}}>
                        {renderDataValues()}
                    </div>
                )
            } else if ((that.state.SeeRGBValues === true) && ((that.state.MobileCheck === false))) {
                return (
                    <div style={{paddingTop: (that.state.ScreenHeight * .22)}}>
                        {renderRGBSliders()}
                    </div>
                )
            }
        }
    }
    //---------------------------------------------------------------------------//
    //Checked box - See Camera

    const [checkedCamera, setCheckedCamera] = React.useState(false);

    const handleChangeCamera = (event) => {
        setCheckedCamera(event.target.checked);
        that.state.enableCamera = event.target.checked;
        console.log("Enable Camera: " + that.state.enableCamera);
    };

    // const videoRef = useRef(null);
    //
    // useEffect(() => {
    //     getVideo();
    // }, [videoRef]);
    //
    // const getVideo = () => {
    //     navigator.mediaDevices
    //         .getUserMedia({ video: { width: that.state.ScreenWidth * .98 , height: 100} })
    //         .then(stream => {
    //             let video = videoRef.current;
    //             video.srcObject = stream;
    //             video.play();
    //         })
    //         .catch(err => {
    //             console.error("error:", err);
    //         });
    // };

    //---------------------------------------------------------------------------//
    //Modal
    window.onload = function() {
        handleOpen()
    }
    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 350,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderCheckBox = () => {
        if ((that.state.MobileCheck === false) || ((that.state.MobileCheck === true) && (that.state.ScreenHeight > that.state.ScreenWidth))) {
            return (
                <div>
                    <label>See RGB Values:</label>
                    <Checkbox
                        align={"center"}
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    /><br/>
                </div>
            )
        }

        if ( ((that.state.MobileCheck === true) && (that.state.ScreenHeight < that.state.ScreenWidth))) {
            return (
                <div>
                    <label>See RGB Values:</label>
                    <Checkbox
                        disabled
                        align={"center"}
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    /><br/>
                </div>
            )
        }
    }

    const renderCameraCheckbox = () => {
        return (
            <div>
                <label>See Camera:</label>
                <Checkbox
                    align={"center"}
                    checked={checkedCamera}
                    onChange={handleChangeCamera}
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /><br/>
            </div>
        )
    }

    const body = (
        <div>
            <div style={modalStyle} className={classes.paper}>
                <IconButton onClick={handleClose} align={"left"}>
                    <CloseIcon></CloseIcon>
                </IconButton>
                <h2 align={"center"} id="simple-modal-title">Help Menu</h2>
                <p align={"left"} id="simple-modal-description">
                    Tap the drop-down before the slider bar towards the bottom of the screen to change between the different test kits: Water, Soil, Plant, Forage, and Low-Range Water.
                </p>
                <p>
                    Move the slider along the bottom bar until color block matches the color in your cuvette.
                </p>
                <hr/>
                <p>Do you have a feature that you would like to have implemented?</p>
                <p>Are there any glitches that you may have found?</p>
                <p>Follow this link to a google forum: <a href={"https://docs.google.com/forms/d/e/1FAIpQLScNnBIK1slwxN5ahtfVtW7NxsWaiM6dU60wiQvxSBMIeJIs2Q/viewform?usp=sf_link"}> Click Here </a></p>
                <hr/>
                <div align={"center"}>
                    {renderCheckBox()}
                    {renderCameraCheckbox()}
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Close Help
                    </Button>
                </div>
            </div>
        </div>
    );

    //----------------------------------------------------------------------------//
    //Changing the Background color of the <Div>..</Div>
    const [valueRed, setValueRed] = React.useState(0);
    const [valueGreen, setValueGreen] = React.useState(0);
    const [valueBlue, setValueBlue] = React.useState(0);
    const [valueOverall, setValueOverall] = React.useState(0);

    const handleChangeRed = (event, newValueRed) => {
        setValueRed(that.setState({Red: newValueRed}));
    };

    const handleChangeGreen = (event, newValueGreen) => {
        setValueGreen(that.setState({Green: newValueGreen}));
    };

    const handleChangeBlue = (event, newValueBlue) => {
        setValueBlue(that.setState({Blue: newValueBlue}));
    };

    const handleChangeOverall = (event, newValueOverall) => {
        setValueOverall(that.setState({OverallSlider: newValueOverall}));
        that.setState({
            Red: that.state.Red = ((-.838 * newValueOverall) + 256),
            Green: that.state.Green = ((237.55 * Math.pow(0.979, newValueOverall))),
            Blue: that.state.Blue = ((-0.904 * newValueOverall) + 254),
        })
    };

    const color = 'rgb(' + that.state.Red + "," + that.state.Green + "," + that.state.Blue + ")";
    console.log(color);

    //--------------------------------------------------------------------------//
    //Variables
    const CalculatedVariables = [
        {id: "Select Test Kit", firstParam: "", secondParam: "", thirdParam: ""},
        {id: "Water", firstParam: (that.state.OverallSlider/10).toFixed(1) + "ppm Nitrate-N", secondParam: (that.state.OverallSlider/2.3).toFixed(1) + "ppm Nitrate", thirdParam: (that.state.OverallSlider/.14).toFixed(1) + "micro-Moles"},
        {id: "Soil", firstParam: (that.state.OverallSlider/2).toFixed(1) + "ppm Nitrate-N", secondParam: (that.state.OverallSlider/.46).toFixed(1) + "ppm Nitrate", thirdParam: (that.state.OverallSlider/.4).toFixed(1) + "N lbs/acre"},
        {id: "Plant", firstParam: (that.state.OverallSlider/.1).toFixed(1) + "ppm Nitrate-N", secondParam: "", thirdParam: ""},
        {id: "Forage", firstParam: (that.state.OverallSlider * 90).toFixed(1) + "ppm Nitrate", secondParam: ((that.state.OverallSlider*90)/4.5).toFixed(1) + "ppm Nitrate-N", thirdParam: ""},
        {id: "Low-Range Water", firstParam: ((that.state.OverallSlider/10)/5).toFixed(1) + "ppm Nitrate-N", secondParam: ((that.state.OverallSlider/2.3)/5).toFixed(1) + "ppm Nitrate", thirdParam: ((that.state.OverallSlider/.14)/5).toFixed(1) + "micro-Moles"},
    ]

    //--------------------------------------------------------------------------//
    //Return Statements
    if ((that.state.MobileCheck === true) && (that.state.ScreenHeight < that.state.ScreenWidth)) {
        return (
            <div>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>
                </div>
                <div>
                    <AppBar position="fixed">
                        <Toolbar variant="dense" style={{backgroundColor: '#222222'}}>
                            <IconButton color="inherit" edge="start" onClick={handleOpen}>
                                <HelpOutlineIcon/>
                            </IconButton>
                            <Typography variant="h5" align="center"
                                        style={{width: "100%", alignItems: "center"}}> Nitrate Color
                                Slider </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <div align={"center"} style={{
                    height: (that.state.ScreenHeight - (that.state.ScreenHeight * .20)),
                    backgroundColor: color
                }}>
                    {renderData()}
                </div>
                <div style={{height: (that.state.ScreenHeight * .10)}}>
                    <Select defaultValue={Select} labelId="label" id="select" defaultValue={"Select Test Kit"}
                            style={{width: (that.state.ScreenWidth * .90), backgroundColor: "lightgray"}}>
                        {CalculatedVariables.map((data, key) => {
                            return (
                                <MenuItem defaultValue={"Water"} key={key}
                                          variant="contained"
                                          color="primary"
                                          value={data.id}
                                          onClick={() => that.switchCalcVarIndex(data.id)}>
                                    {data.id}
                                </MenuItem>
                            );
                        })}

                    </Select>
                </div>
                <div style={{height: (that.state.ScreenHeight * .10)}}>
                    <Slider style={{width: that.state.ScreenWidth * .90}} max={100} value={that.state.OverallSlider}
                            onChange={handleChangeOverall} aria-labelledby="continuous-slider"
                            valueLabelDisplay="auto"/>
                </div>
            </div>
        )
    }

    if ((that.state.MobileCheck === false) || ((that.state.MobileCheck === true) && (that.state.ScreenHeight > that.state.ScreenWidth))) {
        return (
            <div>
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        {body}
                    </Modal>
                </div>
                <div>
                    <AppBar position="fixed">
                        <Toolbar variant="dense" style={{backgroundColor: '#222222'}}>
                            <IconButton color="inherit" edge="start" onClick={handleOpen}>
                                <HelpOutlineIcon/>
                            </IconButton>
                            <Typography variant="h5" align="center"
                                        style={{width: "100%", alignItems: "center"}}> Nitrate Color
                                Slider </Typography>
                        </Toolbar>
                    </AppBar>
                </div>
                <div align={"center"} style={{height: (that.state.ScreenHeight - (that.state.ScreenHeight * .10)), backgroundColor: color}}>
                    {renderData()}
                </div>
                <div style={{height: (that.state.ScreenHeight * .05)}}>
                    <Select defaultValue={Select} labelId="label" id="select" defaultValue={"Select Test Kit"}
                            style={{width: (that.state.ScreenWidth * .90), backgroundColor: "lightgray"}}>
                        {CalculatedVariables.map((data, key) => {
                            return (
                                <MenuItem defaultValue={"Water"} key={key}
                                          variant="contained"
                                          color="primary"
                                          value={data.id}
                                          onClick={() => that.switchCalcVarIndex(data.id)}>
                                    {data.id}
                                </MenuItem>
                            );
                        })}

                    </Select>
                </div>
                <div style={{height: (that.state.ScreenHeight * .05)}}>
                    <Slider style={{width: that.state.ScreenWidth * .90}} max={100} value={that.state.OverallSlider}
                            onChange={handleChangeOverall} aria-labelledby="continuous-slider"
                            valueLabelDisplay="auto"/>
                </div>
            </div>
        )
    }
}

export default BgColor;
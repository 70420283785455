import React, {useEffect, useRef, useState} from "react";
import {
    AppBar,
    Button,
    IconButton,
    makeStyles,
    MenuItem,
    Select,
    Slider,
    Switch,
    Toolbar,
    Typography,
    Modal, Checkbox
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import "./BgColor.css"
import CloseIcon from '@material-ui/icons/Close';

function CameraPhoneLandScape({that1}) {
    //---------------------------------------------------------------------------//
    //Checked box - See Camera
    const videoRef = useRef(null);

    useEffect(() => {
        getVideo();
    }, [videoRef]);

    const getVideo = () => {
        navigator.mediaDevices
            .getUserMedia({ video: {facingMode: 'environment' ,width: that1.state.ScreenWidth * .50 , height: that1.state.ScreenHeight * .80}})
            .then(stream => {
                let video = videoRef.current;
                video.srcObject = stream;
                video.play();
            })
            .catch(err => {
                console.error("error:", err);
            });
    };

        return (
            <div>
                <video ref={videoRef} />
            </div>
        )
}

export default CameraPhoneLandScape;